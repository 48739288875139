import { NavigationMenuInterface } from '@uangcermat/uikit-web';
import { useTranslation } from 'react-i18next';

export const useNavigationConfig = () => {
  const { t } = useTranslation(['menu']);
  const navigationConfig: Array<NavigationMenuInterface> = [
    {
      id: 'incoming',
      icon: 'notebook',
      title: t('menu:activities'),
      children: [
        {
          id: 'incoming.index',
          title: t('menu:activityReport'),
          navLink: '/activities'
        }
      ]
    },
    {
      id: 'activities',
      icon: 'clipboardCheck',
      title: t('menu:planner'),
      children: [
        {
          id: 'activities.planList',
          title: t('menu:activityPlanner'),
          navLink: '/planner'
        }
      ]
    },
    {
      id: 'collectionCase',
      icon: 'activeloan',
      title: t('menu:collections.collections'),
      children: [
        {
          id: 'collectionCase.index',
          title: t('menu:collections.cases'),
          navLink: '/collection-case'
        },
        {
          id: 'group.collectionList',
          title: t('menu:collections.groups'),
          navLink: '/collection-groups'
        }
      ]
    }
  ];

  return { navigationConfig };
};
