import {
  EnglishActivityDetail,
  EnglishActivityList,
  EnglishActivityPlanner,
  EnglishActivityReport,
  EnglishAnnouncement,
  EnglishArchiveDetail,
  EnglishArchiveList,
  EnglishCollectionCases,
  EnglishCollectionGroups,
  EnglishCommon,
  EnglishLoginPage,
  EnglishMenu,
  EnglishProfile,
  EnglishProjectList,
  EnglishRoom,
  EnglishRoomDetail,
  EnglishTeamDetail,
  EnglishTeamList,
  EnglishUserDetail,
  EnglishUserList
} from '@i18n/locales/en';
import {
  BahasaActivityDetail,
  BahasaActivityList,
  BahasaActivityPlanner,
  BahasaActivityReport,
  BahasaAnnouncement,
  BahasaArchiveDetail,
  BahasaArchiveList,
  BahasaCollectionCases,
  BahasaCollectionGroups,
  BahasaCommon,
  BahasaLoginPage,
  BahasaMenu,
  BahasaProfile,
  BahasaProjectList,
  BahasaRoom,
  BahasaRoomDetail,
  BahasaTeamDetail,
  BahasaTeamList,
  BahasaUserDetail,
  BahasaUserList
} from '@i18n/locales/id';
import { LanguageType } from '@interface/UserInterface';
import { localStorageService } from '@utils/localStorage';
// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const useI18n = () => {
  const lng: LanguageType = 'en';

  const changeLanguage = async (to: LanguageType) => {
    if (i18n.isInitialized) {
      i18n.changeLanguage(to);
      localStorageService.setLanguage({ key: 'i18nextLng', value: to });
    }
  };

  const englishResources = {
    common: EnglishCommon,
    collectionCases: EnglishCollectionCases,
    login: EnglishLoginPage,
    menu: EnglishMenu,
    activityReport: EnglishActivityReport,
    activityDetail: EnglishActivityDetail,
    activityList: EnglishActivityList,
    userList: EnglishUserList,
    userDetail: EnglishUserDetail,
    profile: EnglishProfile,
    projectList: EnglishProjectList,
    room: EnglishRoom,
    teamDetail: EnglishTeamDetail,
    teamList: EnglishTeamList,
    announcement: EnglishAnnouncement,
    roomDetail: EnglishRoomDetail,
    archiveDetail: EnglishArchiveDetail,
    archiveList: EnglishArchiveList,
    collectionGroups: EnglishCollectionGroups,
    activityPlanner: EnglishActivityPlanner
  };

  const bahasaResources = {
    common: BahasaCommon,
    login: BahasaLoginPage,
    menu: BahasaMenu,
    collectionCases: BahasaCollectionCases,
    activityReport: BahasaActivityReport,
    activityDetail: BahasaActivityDetail,
    activityList: BahasaActivityList,
    userList: BahasaUserList,
    userDetail: BahasaUserDetail,
    profile: BahasaProfile,
    projectList: BahasaProjectList,
    room: BahasaRoom,
    teamDetail: BahasaTeamDetail,
    teamList: BahasaTeamList,
    announcement: BahasaAnnouncement,
    roomDetail: BahasaRoomDetail,
    archiveDetail: BahasaArchiveDetail,
    archiveList: BahasaArchiveList,
    collectionGroups: BahasaCollectionGroups,
    activityPlanner: BahasaActivityPlanner
  };

  const resources = {
    en: englishResources,
    id: bahasaResources
  };

  const config = {
    resources,
    lng,
    ns: [
      'login',
      'menu',
      'activityReport',
      'activityDetail',
      'userList',
      'userDetail',
      'common',
      'projectList',
      'room',
      'activityList',
      'userList',
      'userDetail',
      'common',
      'teamDetail',
      'teamList',
      'announcement',
      'roomDetail',
      'archiveDetail',
      'archiveList',
      'collectionCases'
    ]
  };

  const init = () => {
    i18n.use(initReactI18next).init(config);
  };

  return { i18n, init, changeLanguage };
};
